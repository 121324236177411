let origin = window.ORIGIN;
//签到状态
const signStatusUrl = `${origin}/gateway/hc-accumulated/dailyPerf/getSignInStatus`;
//签到
const signUrl = `${origin}/gateway/hc-accumulated/dailyPerf/doSignIn`;
//每日任务
const dayMaskUrl = `${origin}/gateway/hc-accumulated/dailyPerf/getSignInStatus`;
//是否显示评论
const isShowSign = `${origin}/gateway/blade-system/application/getFunctionSetting`;
export { signStatusUrl, signUrl, dayMaskUrl, isShowSign };
